<template>
  <div class="step-two">
    <van-cell-group title="A部分 资产充足性测试">
      <van-cell>
        <div v-html="contentA" class="content theme-color"/>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="B部分 个人专业投资者评估">
      <van-cell>
        <div v-html="contentB" class="content theme-color"/>
      </van-cell>
    </van-cell-group>
    <div v-for="(item, index) in form" :key="item.title">
      <div v-if="item.type==='more'" :key="item.title">
        <van-cell-group
          :title="index + 1 + '. ' + item.title" style="padding: 16px; box-sizing: border-box;">
          <van-checkbox-group class="check-group" v-model="form[index].choiced">
            <van-checkbox
              v-for="(it, id) in item.options"
              :key="it"
              :name="id"
              shape="square"
            >{{it}}</van-checkbox>
          </van-checkbox-group>
        </van-cell-group>
        <div
          v-if="form[index].choiced.length > 0 &&
            form[index].right.filter(item => form[index].choiced.indexOf(item) > -1).length
            !== form[index].right.length"
          class="error"
        >抱歉，至少需要选择前两项进行评估！</div>
      </div>
      <van-cell-group
        v-if="item.type==='single' || item.type==='random'" :title="index + 1 + '. ' + item.title">
        <van-cell>
          <van-radio-group
            v-model="form[index].choiced[0]"
            direction="horizontal"
            class="option"
          >
            <van-radio v-for="(it, id) in item.options" :key="it" :name="id">
              {{it}}
            </van-radio>
          </van-radio-group>
        </van-cell>
        <div v-if="item.comment_info && item.choiced[0] === 0">
          <van-field
            v-if="item.comment_info.title === '年均操作次数'"
            v-model="item.comment_info.value"
            input-align="right"
            :label="item.comment_info.title"
            clearable
            placeholder="请输入"
            :formatter="formatterTimes"
            maxlength="100"
          />
          <van-field
            v-else
            v-model="item.comment_info.value"
            input-align="right"
            :label="item.comment_info.title"
            clearable
            placeholder="请输入"
            maxlength="100"
          />
        </div>

        <div
          v-if="form[index].choiced.length > 0
            && form[index].right && form[index].choiced[0] !== form[index].right[0]"
          class="error"
        >抱歉，您的情况不符合专业投资者要求！</div>
      </van-cell-group>
    </div>
    <van-cell-group title="C部分 同意被视为专业投资者">
      <van-cell>
        <div v-html="contentC" class="content theme-color"/>
      </van-cell>
    </van-cell-group>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
        下一步（专业投资者确认）
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Cell, RadioGroup, Radio, Checkbox, Field, CheckboxGroup,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted,
  watch,
  ref,
  reactive,
  computed,
} from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

const contentA = `根据《证券及期货条例》（第 571D 章）《证券及期货（专业投资者）规则》（“专业投资者规则”）第 3 条所列的条件，请提供下列其中一种证明文件，稍后的步骤中需要上传/邮寄。
A、由核数师或注册会计师于最近 12 个月内发出的证明书
B、客户的个人或与其有联系者开立的联名账户，于最近 12 个月内的户口结单（可提交多于一份，以证明总资产值达标）

备注：
1、个人账户，是指单独或联同其有联系者（配偶或其子女）拥有一个联名账户。
2、拥有不少于港币 800 万元或等值外币的投资组合。
3、投资组合包括现金或存款、证劵等；现金或存款包括了储蓄存款、定期存款以及结构性存款等；证券包括股票、债券、债权、票据、基金、窝轮、期权等其他根据证券和期货条例定义的产品。
`.replace(/\r?\n/g, '<br />');

const contentB = `根据《证券及期货事务监察委员会持牌人或注册人操守准则》（《操守准则》）第 15.3B 条所列的条件，香港蜂投证券须在就有关产品及市场对该专业投资者进行评估后，方可获豁免遵守《操守准则》的若干规定。请回答以下问题以便香港蜂投证券作出合理的评估。
`.replace(/\r?\n/g, '<br />');

const contentC = `1、同意被视为专业投资者
本人确认以上由本人填写于此表格的资料是真确、完整及准确，及同意香港蜂投证券有限公司（以下称“香港蜂投证券”）或与香港蜂投证券所属相同企业集团（以下称“FDT 集团”）中的任何公司根据《证券及期货条例》附表一“专业投资者”的定义及《证券及期货（专业投资者）条例》第 3 条视作为“专业投资者”。
2、可获豁免的规条
本人完全明白下列被视为专业投资者可能出现的风险及后果。基于对专业投资者的界定，香港蜂投证券将可豁免遵守《证券及期货事务监察委员会持牌人或注册人操守准则》（《操守准则》）的以下若干要求：
（a）须向客户提供有关本公司和有关其僱员及其他代表其行事的人士的身分和受雇状况的资料
（b）为客户完成交易后，须尽快向客户确认有关该宗交易的重点
（c）须向客户提供关于纳斯达克 - 美国证券交易所试验计划的资料文件
3、撤销专业投资者身份权利
本人明白本人有权在任何时候，就所有投资产品、市场或其他原因给予香港蜂投证券不少于 14 天的书面通知以反对被视为专业投资者及要求撤回专业投资者的资格。
本人同意除非及直至香港蜂投证券收到本人有关的反对及撤回资格之书面通知，香港蜂投证券有权将本人视为专业投资者，本人将承担相关的风险及后果。有关任何本人撤回专业投资者资格的要求，在该撤回要求生效前，均不会妨碍及影响香港蜂投证券向本人提供的服务。
本人承诺会通知香港蜂投证券有关本人之可能构成影响符合被视为专业投资者资格的财政状况变化。
`.replace(/\r?\n/g, '<br />');

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Field.name]: Field,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
    };
  },
  methods: {
    formatterTimes(value) {
      const nValue = value.replace(/[^0-9]+/g, '').substr(0, 20);
      if (Number(nValue) === 0) return '';
      return nValue;
    },
    async next() {
      if (this.disabledNext.value) return;
      const params = JSON.parse(JSON.stringify(this.form));
      const nParams = params.map((item) => {
        const nItem = { ...item };
        if (nItem.type === 'single' || nItem.type === 'random') {
          if (nItem.choiced[0] === 1) {
            nItem.comment_info.value = '';
          }
        }
        return nItem;
      });
      const httprequest = this.isAddAccount
        ? httpAddApi.updateMajorAssessInfo : httpApi.postMajorOptionStepTwo;
      const result = await httprequest(nParams);
      if (result) {
        if (this.isAddAccount) await this.$api.submitLog({ type: 'submitAddMajorAssessInfo' });
        this.$router.push({ path: '/majorOption/step_three' });
      }
    },
    before() {
      this.$router.back();
    },
  },
  setup() {
    const form = reactive([]);
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit('setTitleValue', '风险认知');
      if (isAddAccount.value) {
        store.commit('setStepsValue', ['风险认知(2/4)', '提交资料']);
        store.commit('setStepsActive', 0);
      } else {
        store.commit('setStepsValue', ['填写资料', '风险认知(2/4)', '提交资料']);
        store.commit('setStepsActive', 1);
      }
      const httprequest = isAddAccount.value
        ? httpAddApi.getMajorAssessInfo : httpApi.getMajorOptionStepTwo;
      const result = await httprequest();
      if (!result) return;
      result.data.forEach((item, index) => {
        if (item !== undefined) {
          form[index] = item;
        }
      });
    });
    const disabledNext = ref(true);
    watch(form, (val) => {
      const value = JSON.parse(JSON.stringify(val));
      disabledNext.value = false;
      value.forEach((item) => {
        if (item.type === 'more') {
          if (item.right.filter((it) => item.choiced.indexOf(it) > -1).length
            !== item.right.length) disabledNext.value = true;
        } else if (item.type === 'single') {
          if (item.choiced[0] !== item.right[0]) disabledNext.value = true;
          if (item.choiced[0] === 0 && item.comment_info && !item.comment_info.value.trim()) {
            disabledNext.value = true;
          }
        } else if (item.type === 'random') {
          if (!item.choiced.length) disabledNext.value = true;
          if (item.choiced[0] === 0 && item.comment_info && !item.comment_info.value.trim()) {
            disabledNext.value = true;
          }
        }
      });
      httpApi.formLog(value, disabledNext);
    });
    return {
      isAddAccount,
      contentA,
      contentB,
      contentC,
      form,
      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .step-two {
    text-align: left;
    .title {
      margin-top: @space-1;
      color: @font-color-0;
    }
    .option {
      justify-content: space-around;
    }
    .info {
      margin-top: @space-1;
      padding-left: @space-2;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
    .van-checkbox {
      margin: 16px 0;
    }
    .error {
      color: red;
      padding-left: 16px;
    }
  }
</style>
